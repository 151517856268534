import Img from 'gatsby-image';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { chunk, sum } from 'lodash';
import React, { useState }  from 'react';
import { Box, Link } from 'rebass';

const Gallery = ({ images, itemsPerRow: itemsPerRowByBreakpoints }) => {
  const aspectRatios = images.map(image => image.aspectRatio);

  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios),
      ),
  );

  const carouselFormatters = {
    getAltText: ({ data, index }) =>
      data.caption || `${index + 1}. kép`,
  
    getNextLabel: ({ currentIndex, views}) => `Mutasd a(z) ${currentIndex + 2}. képet a(z) ${views.length} közül`,
    getPrevLabel: ({ currentIndex, views, }) => `Mutasd a(z) ${currentIndex}. képet a(z) ${views.length} közül`,
  
    getNextTitle: () => 'Következő (jobbra nyíl)',
    getPrevTitle: () => 'Előző (balra nyíl)',
  
    getCloseLabel: () => 'Bezárás (esc)',
    getFullscreenLabel: ({ isFullscreen }) =>
      isFullscreen
        ? 'Kilépés a teljes képernyős nézetből (f)'
        : 'Teljes képernyőre váltás (f)',
  };


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const closeModal = () => setModalIsOpen(false);
  const openModal = (imageIndex) => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  return (
    <Box>
      {images.map((image, i) => (
        <Link
          key={image.id}
          href={image.publicURL}
          onClick={(e) => {
            e.preventDefault();
            openModal(i);
          }}
        >
          <Box
            key={i}
            as={Img}
            fluid={image}
            title={image.caption}
            width={rowAspectRatioSumsByBreakpoints.map(
              (rowAspectRatioSums, j) => {
                const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
                const rowAspectRatioSum = rowAspectRatioSums[rowIndex];

                return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`;
              },
            )}
            css={`
              display: inline-block;
              vertical-align: middle;
              transition: filter 0.3s;
              border: 5px solid transparent;
              :hover {
                filter: brightness(87.5%);
              }
            `}
          />
        </Link>
      ))}


      {ModalGateway && (
        <ModalGateway>
          {modalIsOpen && (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map(ip => ({
                  source: ip.srcSetArray.find(im=>im.resolution === '1200').src,
                  caption: '',
                }))}
                currentIndex={modalCurrentIndex}
                formatters={carouselFormatters}
                components={{ FooterCount: () => null }}
              />
            </Modal>
          )}
        </ModalGateway>
      )}
    </Box>
  );
};

export default Gallery;