import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Row, Col } from "../components/page-components/grid";
import SectionTitle from "../components/sectiontitle";
import MD from "gatsby-custom-md";
import Gallery from "../components/gallery";
import "../style/photography-singlepage.less";

const components = {
  row: Row,
  col: Col
};

export default function({ data }) {
  var images = data.markdownRemark.frontmatter.images
    .filter(o => o.imageSrc != null)
    .map(o => {
      return {
        srcSetArray: o.imageSrc.childImageSharp.fluid.srcSet.split(',').map(i => {
          var split = i.split(' ');
          return {
            src: split[0],
            resolution: split[1].replace('w', '')
          }
        }),
        id: o.imageSrc.id,
        publicURL: o.imageSrc.publicURL,
        ...o.imageSrc.childImageSharp.fluid,
        fluid: o.imageSrc.childImageSharp.fluid
      };
    });
  return (
    <Layout>
      <SEO
        lang="en"
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        image={data.markdownRemark.frontmatter.image.publicURL}
      />
      <div className="container sectionDiv">
        <div className="section-title">
          <SectionTitle
            title={data.markdownRemark.frontmatter.title.toUpperCase()}
          />
        </div>
        <article className="photography-post">
          <div className="content row flex">
            <div
              className="col s12"
              style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                overflow: "auto"
              }}
            >
              <MD
                components={components}
                htmlAst={data.markdownRemark.htmlAst}
              />
              <Gallery
                itemsPerRow={[2, 3]} // Custom responsive prop
                images={images}
              />
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      id
      frontmatter {
        title
        date
        description
        images {
          imageSrc {
            publicURL
            childImageSharp {
              fluid {
                srcSet
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        image {
          childImageSharp {
            fluid {
              srcSet
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
